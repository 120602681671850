.loginCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-color: #0000;
    --background: pink;
}

.loginPage {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -100;
}


.center {
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
}

.centered {
    margin-left: 22.5px;
}
.login-container {
    --background: rgb(0, 0, 0);
    background-size: cover;
    box-shadow: 0 0 50px black;
    transform: translate(-50%, -50%);
    margin-top: auto;

}

.login-box {
    width: 80%;
    margin: auto;
    /*padding-top: 35px;*/
    padding-bottom: 16px;
    /*margin-bottom: 40px;*/
}

.login-box-image {
    /*height: 120px;*/
    margin-bottom: 35px;
}
