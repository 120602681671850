.lst-kix_list_14-1>li:before {
    content: "-  "
}

.lst-kix_list_14-3>li:before {
    content: "-  "
}

ul.lst-kix_list_1-0 {
    list-style-type: none
}

.lst-kix_list_14-0>li:before {
    content: "-  "
}

.lst-kix_list_14-4>li:before {
    content: "-  "
}

.lst-kix_list_14-5>li:before {
    content: "-  "
}

.lst-kix_list_14-7>li:before {
    content: "-  "
}

.lst-kix_list_14-6>li:before {
    content: "-  "
}

ul.lst-kix_list_9-3 {
    list-style-type: none
}

ul.lst-kix_list_9-4 {
    list-style-type: none
}

ul.lst-kix_list_9-1 {
    list-style-type: none
}

ul.lst-kix_list_9-2 {
    list-style-type: none
}

ul.lst-kix_list_9-7 {
    list-style-type: none
}

ul.lst-kix_list_9-8 {
    list-style-type: none
}

ul.lst-kix_list_9-5 {
    list-style-type: none
}

ul.lst-kix_list_9-6 {
    list-style-type: none
}

ul.lst-kix_list_1-3 {
    list-style-type: none
}

ul.lst-kix_list_1-4 {
    list-style-type: none
}

ul.lst-kix_list_1-1 {
    list-style-type: none
}

ul.lst-kix_list_1-2 {
    list-style-type: none
}

ul.lst-kix_list_1-7 {
    list-style-type: none
}

ul.lst-kix_list_9-0 {
    list-style-type: none
}

ul.lst-kix_list_1-8 {
    list-style-type: none
}

ul.lst-kix_list_1-5 {
    list-style-type: none
}

.lst-kix_list_14-2>li:before {
    content: "-  "
}

ul.lst-kix_list_1-6 {
    list-style-type: none
}

.lst-kix_list_14-8>li:before {
    content: "-  "
}

.lst-kix_list_5-0>li:before {
    content: "-  "
}

.lst-kix_list_5-3>li:before {
    content: "-  "
}

.lst-kix_list_5-2>li:before {
    content: "-  "
}

.lst-kix_list_5-1>li:before {
    content: "-  "
}

.lst-kix_list_5-7>li:before {
    content: "-  "
}

ul.lst-kix_list_8-4 {
    list-style-type: none
}

ul.lst-kix_list_8-5 {
    list-style-type: none
}

.lst-kix_list_5-6>li:before {
    content: "-  "
}

.lst-kix_list_5-8>li:before {
    content: "-  "
}

ul.lst-kix_list_8-2 {
    list-style-type: none
}

ul.lst-kix_list_8-3 {
    list-style-type: none
}

ul.lst-kix_list_8-8 {
    list-style-type: none
}

ul.lst-kix_list_8-6 {
    list-style-type: none
}

ul.lst-kix_list_8-7 {
    list-style-type: none
}

.lst-kix_list_5-4>li:before {
    content: "-  "
}

.lst-kix_list_5-5>li:before {
    content: "-  "
}

ul.lst-kix_list_8-0 {
    list-style-type: none
}

ul.lst-kix_list_8-1 {
    list-style-type: none
}

.lst-kix_list_6-1>li:before {
    content: "-  "
}

.lst-kix_list_6-3>li:before {
    content: "-  "
}

.lst-kix_list_6-0>li:before {
    content: "-  "
}

.lst-kix_list_6-4>li:before {
    content: "-  "
}

.lst-kix_list_6-2>li:before {
    content: "-  "
}

.lst-kix_list_6-8>li:before {
    content: "-  "
}

.lst-kix_list_6-5>li:before {
    content: "-  "
}

.lst-kix_list_6-7>li:before {
    content: "-  "
}

.lst-kix_list_6-6>li:before {
    content: "-  "
}

.lst-kix_list_2-7>li:before {
    content: "-  "
}

.lst-kix_list_7-4>li:before {
    content: "-  "
}

.lst-kix_list_7-6>li:before {
    content: "-  "
}

.lst-kix_list_2-5>li:before {
    content: "-  "
}

.lst-kix_list_7-2>li:before {
    content: "-  "
}

ul.lst-kix_list_3-7 {
    list-style-type: none
}

ul.lst-kix_list_3-8 {
    list-style-type: none
}

.lst-kix_list_10-1>li:before {
    content: "-  "
}

.lst-kix_list_13-7>li:before {
    content: "-  "
}

ul.lst-kix_list_3-1 {
    list-style-type: none
}

ul.lst-kix_list_3-2 {
    list-style-type: none
}

.lst-kix_list_7-8>li:before {
    content: "-  "
}

ul.lst-kix_list_3-0 {
    list-style-type: none
}

ul.lst-kix_list_3-5 {
    list-style-type: none
}

ul.lst-kix_list_3-6 {
    list-style-type: none
}

ul.lst-kix_list_3-3 {
    list-style-type: none
}

ul.lst-kix_list_3-4 {
    list-style-type: none
}

.lst-kix_list_10-7>li:before {
    content: "-  "
}

.lst-kix_list_10-5>li:before {
    content: "-  "
}

.lst-kix_list_10-3>li:before {
    content: "-  "
}

ul.lst-kix_list_11-7 {
    list-style-type: none
}

ul.lst-kix_list_11-6 {
    list-style-type: none
}

.lst-kix_list_4-1>li:before {
    content: "-  "
}

ul.lst-kix_list_11-5 {
    list-style-type: none
}

ul.lst-kix_list_11-4 {
    list-style-type: none
}

ul.lst-kix_list_11-3 {
    list-style-type: none
}

ul.lst-kix_list_11-2 {
    list-style-type: none
}

ul.lst-kix_list_11-1 {
    list-style-type: none
}

ul.lst-kix_list_11-0 {
    list-style-type: none
}

.lst-kix_list_9-2>li:before {
    content: "-  "
}

.lst-kix_list_4-3>li:before {
    content: "-  "
}

.lst-kix_list_4-5>li:before {
    content: "-  "
}

ul.lst-kix_list_11-8 {
    list-style-type: none
}

.lst-kix_list_9-0>li:before {
    content: "-  "
}

.lst-kix_list_9-6>li:before {
    content: "-  "
}

.lst-kix_list_9-4>li:before {
    content: "-  "
}

.lst-kix_list_11-3>li:before {
    content: "-  "
}

ul.lst-kix_list_2-8 {
    list-style-type: none
}

.lst-kix_list_12-3>li:before {
    content: "-  "
}

.lst-kix_list_11-5>li:before {
    content: "-  "
}

.lst-kix_list_12-1>li:before {
    content: "-  "
}

ul.lst-kix_list_2-2 {
    list-style-type: none
}

ul.lst-kix_list_2-3 {
    list-style-type: none
}

ul.lst-kix_list_2-0 {
    list-style-type: none
}

ul.lst-kix_list_2-1 {
    list-style-type: none
}

.lst-kix_list_9-8>li:before {
    content: "-  "
}

ul.lst-kix_list_2-6 {
    list-style-type: none
}

.lst-kix_list_1-1>li:before {
    content: "-  "
}

ul.lst-kix_list_2-7 {
    list-style-type: none
}

.lst-kix_list_11-7>li:before {
    content: "-  "
}

ul.lst-kix_list_2-4 {
    list-style-type: none
}

ul.lst-kix_list_2-5 {
    list-style-type: none
}

ul.lst-kix_list_10-0 {
    list-style-type: none
}

.lst-kix_list_1-3>li:before {
    content: "-  "
}

.lst-kix_list_13-3>li:before {
    content: "-  "
}

ul.lst-kix_list_10-8 {
    list-style-type: none
}

ul.lst-kix_list_10-7 {
    list-style-type: none
}

.lst-kix_list_1-7>li:before {
    content: "-  "
}

ul.lst-kix_list_10-6 {
    list-style-type: none
}

ul.lst-kix_list_10-5 {
    list-style-type: none
}

ul.lst-kix_list_10-4 {
    list-style-type: none
}

ul.lst-kix_list_10-3 {
    list-style-type: none
}

.lst-kix_list_1-5>li:before {
    content: "-  "
}

ul.lst-kix_list_10-2 {
    list-style-type: none
}

ul.lst-kix_list_10-1 {
    list-style-type: none
}

.lst-kix_list_13-5>li:before {
    content: "-  "
}

.lst-kix_list_12-5>li:before {
    content: "-  "
}

.lst-kix_list_12-7>li:before {
    content: "-  "
}

.lst-kix_list_2-1>li:before {
    content: "-  "
}

.lst-kix_list_2-3>li:before {
    content: "-  "
}

.lst-kix_list_13-1>li:before {
    content: "-  "
}

.lst-kix_list_3-0>li:before {
    content: "-  "
}

ul.lst-kix_list_5-7 {
    list-style-type: none
}

ul.lst-kix_list_5-8 {
    list-style-type: none
}

.lst-kix_list_3-1>li:before {
    content: "-  "
}

.lst-kix_list_3-2>li:before {
    content: "-  "
}

ul.lst-kix_list_5-5 {
    list-style-type: none
}

ul.lst-kix_list_5-6 {
    list-style-type: none
}

.lst-kix_list_8-1>li:before {
    content: "-  "
}

.lst-kix_list_8-2>li:before {
    content: "-  "
}

.lst-kix_list_3-5>li:before {
    content: "-  "
}

ul.lst-kix_list_5-0 {
    list-style-type: none
}

.lst-kix_list_3-4>li:before {
    content: "-  "
}

ul.lst-kix_list_5-3 {
    list-style-type: none
}

.lst-kix_list_3-3>li:before {
    content: "-  "
}

ul.lst-kix_list_5-4 {
    list-style-type: none
}

ul.lst-kix_list_5-1 {
    list-style-type: none
}

.lst-kix_list_8-0>li:before {
    content: "-  "
}

ul.lst-kix_list_5-2 {
    list-style-type: none
}

.lst-kix_list_8-7>li:before {
    content: "-  "
}

.lst-kix_list_3-8>li:before {
    content: "-  "
}

.lst-kix_list_8-5>li:before {
    content: "-  "
}

.lst-kix_list_8-6>li:before {
    content: "-  "
}

.lst-kix_list_8-3>li:before {
    content: "-  "
}

ul.lst-kix_list_13-5 {
    list-style-type: none
}

ul.lst-kix_list_13-4 {
    list-style-type: none
}

ul.lst-kix_list_13-3 {
    list-style-type: none
}

.lst-kix_list_3-6>li:before {
    content: "-  "
}

ul.lst-kix_list_13-2 {
    list-style-type: none
}

ul.lst-kix_list_13-1 {
    list-style-type: none
}

.lst-kix_list_3-7>li:before {
    content: "-  "
}

ul.lst-kix_list_13-0 {
    list-style-type: none
}

.lst-kix_list_8-4>li:before {
    content: "-  "
}

.lst-kix_list_11-2>li:before {
    content: "-  "
}

ul.lst-kix_list_13-8 {
    list-style-type: none
}

.lst-kix_list_11-1>li:before {
    content: "-  "
}

ul.lst-kix_list_13-7 {
    list-style-type: none
}

ul.lst-kix_list_13-6 {
    list-style-type: none
}

.lst-kix_list_11-0>li:before {
    content: "-  "
}

.lst-kix_list_8-8>li:before {
    content: "-  "
}

.lst-kix_list_4-8>li:before {
    content: "-  "
}

.lst-kix_list_4-7>li:before {
    content: "-  "
}

ul.lst-kix_list_4-8 {
    list-style-type: none
}

ul.lst-kix_list_4-6 {
    list-style-type: none
}

ul.lst-kix_list_4-7 {
    list-style-type: none
}

ul.lst-kix_list_4-0 {
    list-style-type: none
}

ul.lst-kix_list_4-1 {
    list-style-type: none
}

ul.lst-kix_list_4-4 {
    list-style-type: none
}

ul.lst-kix_list_4-5 {
    list-style-type: none
}

ul.lst-kix_list_4-2 {
    list-style-type: none
}

ul.lst-kix_list_4-3 {
    list-style-type: none
}

ul.lst-kix_list_12-6 {
    list-style-type: none
}

ul.lst-kix_list_12-5 {
    list-style-type: none
}

ul.lst-kix_list_12-4 {
    list-style-type: none
}

ul.lst-kix_list_12-3 {
    list-style-type: none
}

ul.lst-kix_list_12-2 {
    list-style-type: none
}

ul.lst-kix_list_12-1 {
    list-style-type: none
}

ul.lst-kix_list_12-0 {
    list-style-type: none
}

ul.lst-kix_list_12-8 {
    list-style-type: none
}

ul.lst-kix_list_12-7 {
    list-style-type: none
}

.lst-kix_list_7-0>li:before {
    content: "-  "
}

.lst-kix_list_2-6>li:before {
    content: "-  "
}

.lst-kix_list_2-4>li:before {
    content: "-  "
}

.lst-kix_list_2-8>li:before {
    content: "-  "
}

.lst-kix_list_7-1>li:before {
    content: "-  "
}

.lst-kix_list_7-5>li:before {
    content: "-  "
}

.lst-kix_list_7-3>li:before {
    content: "-  "
}

ul.lst-kix_list_7-5 {
    list-style-type: none
}

.lst-kix_list_10-0>li:before {
    content: "-  "
}

ul.lst-kix_list_7-6 {
    list-style-type: none
}

ul.lst-kix_list_7-3 {
    list-style-type: none
}

ul.lst-kix_list_7-4 {
    list-style-type: none
}

.lst-kix_list_13-6>li:before {
    content: "-  "
}

.lst-kix_list_13-8>li:before {
    content: "-  "
}

ul.lst-kix_list_7-7 {
    list-style-type: none
}

ul.lst-kix_list_7-8 {
    list-style-type: none
}

ul.lst-kix_list_7-1 {
    list-style-type: none
}

ul.lst-kix_list_7-2 {
    list-style-type: none
}

ul.lst-kix_list_7-0 {
    list-style-type: none
}

.lst-kix_list_7-7>li:before {
    content: "-  "
}

.lst-kix_list_10-4>li:before {
    content: "-  "
}

.lst-kix_list_10-8>li:before {
    content: "-  "
}

.lst-kix_list_4-0>li:before {
    content: "-  "
}

.lst-kix_list_10-2>li:before {
    content: "-  "
}

.lst-kix_list_4-4>li:before {
    content: "-  "
}

.lst-kix_list_4-2>li:before {
    content: "-  "
}

.lst-kix_list_4-6>li:before {
    content: "-  "
}

.lst-kix_list_9-3>li:before {
    content: "-  "
}

.lst-kix_list_10-6>li:before {
    content: "-  "
}

.lst-kix_list_9-1>li:before {
    content: "-  "
}

.lst-kix_list_9-7>li:before {
    content: "-  "
}

.lst-kix_list_11-4>li:before {
    content: "-  "
}

.lst-kix_list_12-4>li:before {
    content: "-  "
}

.lst-kix_list_9-5>li:before {
    content: "-  "
}

ul.lst-kix_list_6-6 {
    list-style-type: none
}

ul.lst-kix_list_6-7 {
    list-style-type: none
}

ul.lst-kix_list_6-4 {
    list-style-type: none
}

ul.lst-kix_list_6-5 {
    list-style-type: none
}

ul.lst-kix_list_6-8 {
    list-style-type: none
}

.lst-kix_list_12-2>li:before {
    content: "-  "
}

.lst-kix_list_11-6>li:before {
    content: "-  "
}

.lst-kix_list_1-0>li:before {
    content: "-  "
}

ul.lst-kix_list_6-2 {
    list-style-type: none
}

.lst-kix_list_11-8>li:before {
    content: "-  "
}

ul.lst-kix_list_6-3 {
    list-style-type: none
}

.lst-kix_list_1-2>li:before {
    content: "-  "
}

ul.lst-kix_list_6-0 {
    list-style-type: none
}

.lst-kix_list_12-0>li:before {
    content: "-  "
}

ul.lst-kix_list_6-1 {
    list-style-type: none
}

.lst-kix_list_1-4>li:before {
    content: "-  "
}

.lst-kix_list_13-0>li:before {
    content: "-  "
}

ul.lst-kix_list_14-4 {
    list-style-type: none
}

ul.lst-kix_list_14-3 {
    list-style-type: none
}

ul.lst-kix_list_14-2 {
    list-style-type: none
}

.lst-kix_list_13-4>li:before {
    content: "-  "
}

ul.lst-kix_list_14-1 {
    list-style-type: none
}

ul.lst-kix_list_14-0 {
    list-style-type: none
}

.lst-kix_list_1-6>li:before {
    content: "-  "
}

ul.lst-kix_list_14-8 {
    list-style-type: none
}

ul.lst-kix_list_14-7 {
    list-style-type: none
}

.lst-kix_list_2-0>li:before {
    content: "-  "
}

.lst-kix_list_12-6>li:before {
    content: "-  "
}

ul.lst-kix_list_14-6 {
    list-style-type: none
}

ul.lst-kix_list_14-5 {
    list-style-type: none
}

.lst-kix_list_1-8>li:before {
    content: "-  "
}

.lst-kix_list_2-2>li:before {
    content: "-  "
}

.lst-kix_list_13-2>li:before {
    content: "-  "
}

.lst-kix_list_12-8>li:before {
    content: "-  "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c10 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.375;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c7 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-family: "Arial";
    font-style: normal
}

.c0 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c13 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.375;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

.c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c15 {
    color: #000000;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

.c9 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline
}

.c12 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
}

.c2 {
    padding: 0;
    margin: 0
}

.c5 {
    height: 11pt
}

.c8 {
    font-style: italic
}

.c14 {
    margin-left: 36pt
}

.c11 {
    font-size: 11pt
}

.c6 {
    font-weight: 700
}

.c1 {
    font-size: 12pt
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}