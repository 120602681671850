.parentSection {
    display: flex;
    align-items: center;
}

.commentsIcon {
    font-size: 59px;
    margin: 10px;
}

.btnTitleComments {
    font-size: 19px;
}
.medumText {
    font-size: 18px;
    color: #999;
}
