.FireLogoPAssword{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ButtonColorForgot{
    color: #005494;
    --border-radius: 0;
    width: 100%;

}
.TextPassDesc{
    font-size: 12px;
    text-align: center;
    color: gray !important;
}