
.jfh-header {
  background: #005494;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  padding-top: 40px;
}

.titleHeader {
  color: white;
  font-weight: bold;
  font-size: 28px;
}

.logoImage {
  width: 100px;
  height: 100px;
}
.flexLabelMenu {
  justify-content: flex-start !important;
  display: flex !important;
}
.marginStart {
  margin-left: 32px !important;
}
