.app {
    --background: #005494;
}

.menuButton {
    color: #ffffff;
}

.appContent {
    padding-top: 10%;
    color: #005494;
}

.img-responsive {
    width: fit-content;
}

.alliesBtn:hover {
    --background: #f5f5ff;
}
.alliesBtn {
    z-index: 1;
    flex: 1;
    max-width: 193px;
    height: 42px;
    --box-shadow: -2px 2px 4px -2px dimgray;
    --background: #ffffff;
    --color: #005494;
    --border-radius: 8px 0px 0px 8px;
    --border-style: solid;
    --border-width: 2px 0px 2px 2px;
    --border-color: #005494;
}

.rivalsBtn{
    flex: 1;
    max-width: 193px;
    height: 42px;
    --box-shadow: 0px 5px 4px -4px dimgray;
    --border-radius: 0px 8px 8px 0px;
    --ion-color-primary: #005494;
}

.profile-head {
    transform: translateY(5rem)
}

.cover {
    background-image: url(https://images.unsplash.com/photo-1530305408560-82d13781b33a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80);
    background-size: cover;
    background-repeat: no-repeat
}

/*
body {
    background: #654ea3;
    background: linear-gradient(to right, #005494, #ffffff);
    min-height: 100vh;
    overflow-x: hidden
}*/
