.container { position:relative; }
.container video {
    position:relative;
    z-index:0;
}
.overlay {
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    width: 100%;
    height: 100%;
}

.deliveryModuleTitle {
    color: white;
    background: #005494;
    margin-left: 5px;
    font-family: freight-sans-compressed-pro, sans-serif;
    font-size:14pt;
}

.reportBtn {
    font-family: freight-sans-compressed-pro, sans-serif;
    font-weight: 700;
    font-size:14pt;
}
.highContrast {
    color: white;
}

.left {
    float: left;
}

 .right {
     float: right;
 }

.missionDeliveryTitle {
    color: #005494;
    background: #ffffff;
    margin-left: 5px;
    font-family: freight-sans-compressed-pro, sans-serif;
    font-size:14pt;
}