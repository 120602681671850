@import
url('https://themes.googleusercontent.com/fonts/css?kit=sDU-RIIs3Wq_4pUcDwWu-wOmIdclY-ma4FjuOD-IO5phBSK8TwnmX7_MxI-MEYS8BRFKUaAHKqnvhGgz58RGvPesZW2xOQ-xsNqO47m55DA');
ol.lst-kix_list_1-3 {
    list-style-type:none
}

ol.lst-kix_list_1-4 {
    list-style-type:none
}

.lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
}

.lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, decimal) ". "
}

.lst-kix_list_2-7 > li {
    counter-increment:lst-ctn-kix_list_2-7
}

ol.lst-kix_list_1-5 {
    list-style-type:none
}

ol.lst-kix_list_1-6 {
    list-style-type:none
}

.lst-kix_list_2-1 > li {
    counter-increment:lst-ctn-kix_list_2-1
}

ol.lst-kix_list_1-0 {
    list-style-type:none
}

.lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-4, decimal) ". "
}

.lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, decimal) ". "
}

.lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, decimal) ". "
}

ol.lst-kix_list_1-1 {
    list-style-type:none
}

ol.lst-kix_list_1-2 {
    list-style-type:none
}

.lst-kix_list_1-1 > li {
    counter-increment:lst-ctn-kix_list_1-1
}

ol.lst-kix_list_2-6.start {
    counter-reset:lst-ctn-kix_list_2-6 0
}

ol.lst-kix_list_1-8.start {
    counter-reset:lst-ctn-kix_list_1-8 0
}

ol.lst-kix_list_2-3.start {
    counter-reset:lst-ctn-kix_list_2-3 0
}

ol.lst-kix_list_1-5.start {
    counter-reset:lst-ctn-kix_list_1-5 0
}

ol.lst-kix_list_1-7 {
    list-style-type:none
}

.lst-kix_list_1-7 > li {
    counter-increment:lst-ctn-kix_list_1-7
}

ol.lst-kix_list_1-8 {
    list-style-type:none
}

ol.lst-kix_list_2-5.start {
    counter-reset:lst-ctn-kix_list_2-5 0
}

.lst-kix_list_2-0 > li {
    counter-increment:lst-ctn-kix_list_2-0
}

.lst-kix_list_2-3 > li {
    counter-increment:lst-ctn-kix_list_2-3
}

.lst-kix_list_2-6 > li {
    counter-increment:lst-ctn-kix_list_2-6
}

ol.lst-kix_list_1-7.start {
    counter-reset:lst-ctn-kix_list_1-7 0
}

.lst-kix_list_1-2 > li {
    counter-increment:lst-ctn-kix_list_1-2
}

ol.lst-kix_list_2-2.start {
    counter-reset:lst-ctn-kix_list_2-2 4
}

.lst-kix_list_1-5 > li {
    counter-increment:lst-ctn-kix_list_1-5
}

.lst-kix_list_1-8 > li {
    counter-increment:lst-ctn-kix_list_1-8
}

ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
}

ol.lst-kix_list_1-1.start {
    counter-reset:lst-ctn-kix_list_1-1 0
}

ol.lst-kix_list_2-2 {
    list-style-type:none
}

ol.lst-kix_list_2-3 {
    list-style-type:none
}

ol.lst-kix_list_2-4 {
    list-style-type:none
}

ol.lst-kix_list_2-5 {
    list-style-type:none
}

.lst-kix_list_1-4 > li {
    counter-increment:lst-ctn-kix_list_1-4
}

ol.lst-kix_list_2-0 {
    list-style-type:none
}

.lst-kix_list_2-4 > li {
    counter-increment:lst-ctn-kix_list_2-4
}

ol.lst-kix_list_1-6.start {
    counter-reset:lst-ctn-kix_list_1-6 0
}

ol.lst-kix_list_2-1 {
    list-style-type:none
}

ol.lst-kix_list_1-3.start {
    counter-reset:lst-ctn-kix_list_1-3 0
}

ol.lst-kix_list_2-8.start {
    counter-reset:lst-ctn-kix_list_2-8 0
}

ol.lst-kix_list_1-2.start {
    counter-reset:lst-ctn-kix_list_1-2 0
}

.lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
}

ol.lst-kix_list_2-6 {
    list-style-type:none
}

.lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". "
}

.lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". "
}

ol.lst-kix_list_2-0.start {
    counter-reset:lst-ctn-kix_list_2-0 0
}

ol.lst-kix_list_2-7 {
    list-style-type:none
}

ol.lst-kix_list_2-8 {
    list-style-type:none
}

.lst-kix_list_1-3 > li:before {
    content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "
}

.lst-kix_list_1-4 > li:before {
    content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". "
}

ol.lst-kix_list_1-0.start {
    counter-reset:lst-ctn-kix_list_1-0 0
}

.lst-kix_list_1-0 > li {
    counter-increment:lst-ctn-kix_list_1-0
}

.lst-kix_list_1-6 > li {
    counter-increment:lst-ctn-kix_list_1-6
}

.lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-7, decimal) ". "
}

ol.lst-kix_list_2-7.start {
    counter-reset:lst-ctn-kix_list_2-7 0
}

.lst-kix_list_1-3 > li {
    counter-increment:lst-ctn-kix_list_1-3
}

.lst-kix_list_1-5 > li:before {
    content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". "
}

.lst-kix_list_1-6 > li:before {
    content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "
}

.lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "
}

.lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". "
}

ol.lst-kix_list_2-1.start {
    counter-reset:lst-ctn-kix_list_2-1 0
}

.lst-kix_list_2-5 > li {
    counter-increment:lst-ctn-kix_list_2-5
}

.lst-kix_list_2-8 > li {
    counter-increment:lst-ctn-kix_list_2-8
}

.lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-8, decimal) ". "
}

.lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". "
}

.lst-kix_list_2-3 > li:before {
    content: "" counter(lst-ctn-kix_list_2-3, decimal) ". "
}

.lst-kix_list_2-2 > li {
    counter-increment:lst-ctn-kix_list_2-2
}

ol.lst-kix_list_2-4.start {
    counter-reset:lst-ctn-kix_list_2-4 0
}

ol {
    margin: 0;
    padding:0
}

table td, table th {
    padding:0
}

.c2 {
    background-color: #eff4f5;
    margin-left: 72pt;
    padding-top: 13.7pt;
    list-style-position: inside;
    text-indent: 90pt;
    padding-bottom: 13.7pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align:left
}

.c22 {
    background-color: #eff4f5;
    margin-left: 108pt;
    padding-top: 13.7pt;
    list-style-position: inside;
    text-indent: 135pt;
    padding-bottom: 9pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align:left
}

.c7 {
    background-color: #eff4f5;
    margin-left: 108pt;
    padding-top: 13.7pt;
    list-style-position: inside;
    text-indent: 135pt;
    padding-bottom: 13.7pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align:left
}

.c10 {
    background-color: #eff4f5;
    padding-top: 13.7pt;
    padding-bottom: 13.7pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align:center
}

.c8 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 25.5pt;
    font-family: "Helvetica Neue";
    font-style:normal
}

.c0 {
    -webkit-text-decoration-skip: none;
    color: #595959;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 11.5pt;
    font-family: "Helvetica Neue"
}

.c11 {
    background-color: #eff4f5;
    padding-top: 13.7pt;
    padding-bottom: 13.7pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align:left
}

.c19 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Cambria";
    font-style:normal
}

.c25 {
    background-color: #eff4f5;
    padding-top: 0pt;
    padding-bottom: 48pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align:left
}

.c9 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Times New Roman";
    font-style:normal
}

.c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height:12pt
}

.c3 {
    color: #595959;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11.5pt;
    font-family: "Helvetica Neue";
    font-style:normal
}

.c1 {
    font-size: 11.5pt;
    font-family: "Helvetica Neue";
    font-style: italic;
    color: #595959;
    font-weight:400
}

.c4 {
    font-size: 11.5pt;
    font-family: "Helvetica Neue";
    color: #595959;
    font-weight:400
}

.c17 {
    font-size: 11.5pt;
    font-family: "Helvetica Neue";
    color: #082135;
    font-weight:400
}

.c15 {
    font-size: 11.5pt;
    font-family: "Helvetica Neue";
    color: #595959;
    font-weight:700
}

.c14 {
    margin-left: 216pt;
    list-style-position: inside;
    text-indent:270pt
}

.c21 {
    margin-left: 180pt;
    list-style-position: inside;
    text-indent:225pt
}

.c18 {
    background-color: #ffffff;
    max-width: 540pt;
    padding:72pt 36pt 72pt 36pt
}

.c13 {
    margin-left: 144pt;
    list-style-position: inside;
    text-indent:180pt
}

.c12 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink:none
}

.c24 {
    margin-left: 36pt;
    list-style-position: inside;
    text-indent:90pt
}

.c6 {
    padding: 0;
    margin:0
}

.c23 {
    color: inherit;
    text-decoration:inherit
}

.c16 {
    height:12pt
}

.c20 {
    margin-left:126pt
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align:left
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align:left
}

li {
    color: #000000;
    font-size: 12pt;
    font-family: "Cambria"
}

p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Cambria"
}

h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align:left
}

h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 0pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align:left
}

h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align:left
}

h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align:left
}

h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align:left
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}