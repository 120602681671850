.app {
    --background: #005494;
}

.menuButton {
    color: #ffffff;
}

.appContent {
    padding-top: 10%;
}


.menuButton:hover
{
  opacity:1;
  transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  -webkit-transition: opacity .2s ease-out;
  -o-transition: opacity .2s ease-out;
}


