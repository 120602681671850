.video-responsive {
    width: 100vw;
    height:auto;
}

.btn-accept {
    --color: #005494;
    --background: yellow;
}

.btn-complete {
    --background: #005494;
}

.lbl-accept {
    --color:#005494;
}

.lbl-complete {
    color: #ffffff;
    --color: #ffffff;
}